import clsx from 'clsx'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useRef, useState } from 'react'

import BackButton from '@/components/BackButton'
import Link from '@/components/Link'
import ItemProps from '@/components/Menu/ItemProps'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Menu.module.scss'
import { useMenu } from '@/bus/menu'
import { useUi } from '@/bus/ui'
import { routes } from '@/config'

interface DesktopProps extends DefaultComponentProps {
  items: ItemProps[]
}

const Desktop: FC<DesktopProps> = ({ className = '', items = [] }) => {
  const [hoverAnchor, setHoverAnchor] = useState<string | null>()
  const [activeAnchor, setActiveAnchor] = useState<string | null>()
  const itemsRef = useRef<{ [key: string]: any }>({})
  const [bgStyles, setBgStyles] = useState<object>({ width: 0, left: 0 })
  const router = useRouter()
  const { settings } = useUi()
  const { activeItem, setActiveItem } = useMenu()

  useEffect(() => {
    if (!activeItem) {
      return
    }
    setActiveAnchor(activeItem)
  }, [activeItem])

  useEffect(() => {
    const styles = { width: 0, left: 0 }
    if (hoverAnchor) {
      const { offsetLeft = 0, offsetWidth = 0 } =
        itemsRef.current?.[hoverAnchor] ?? {}
      styles.width = offsetWidth
      styles.left = offsetLeft
    }
    setBgStyles(styles)
  }, [hoverAnchor])

  const handleClick = useCallback((id: string) => {
    setActiveItem(id)
  }, [])

  const handleEnter = useCallback((id: string) => {
    setHoverAnchor(id)
  }, [])

  const handleLeave = useCallback(() => {
    setHoverAnchor(activeAnchor)
  }, [activeAnchor])

  useEffect(() => {
    if (!activeAnchor) {
      return
    }
    setHoverAnchor(activeAnchor)
  }, [activeAnchor])

  return (
    <nav className={clsx(styles['menu'], className)}>
      {settings.backButton && (
        <BackButton
          className={styles['menu__back-button']}
          url={routes.NETWORKS}
        />
      )}
      <ul className={styles['menu__list']}>
        {items.map(({ id, label }) => {
          const url = routes[id.toUpperCase()]
          return (
            <li
              ref={el => (itemsRef.current[id] = el)}
              key={id}
              className={styles['menu__item']}
            >
              <Link
                className={clsx(styles['menu__link'], {
                  [styles['menu__link_active']]:
                    activeAnchor === id || router.pathname === url
                })}
                href={url}
                onMouseEnter={() => handleEnter(id)}
                onMouseLeave={handleLeave}
                onClick={() => handleClick(id)}
                title={label}
              >
                {label}
              </Link>
            </li>
          )
        })}
        <li className={styles['menu__bg']} style={bgStyles} />
      </ul>
    </nav>
  )
}

export default Desktop

import { call, put } from 'redux-saga/effects'

import { apiErrorAction, setErrorAction, setLoadingAction } from '../actions'

import { showNotificationAction } from '@/bus/notifications'

export function* apiErrorWorker(action) {
  const { status, statusText } = action.payload

  if (status >= 500) {
    if (statusText) {
      yield put(showNotificationAction({ message: statusText, type: 'error' }))
    }
  }
}

export function* fetchDataWorker({ params, api, type }) {
  yield put(
    setLoadingAction({
      type,
      payload: { loading: true, failed: false }
    })
  )

  try {
    const response = yield call(api, params)
    const { status, data } = response

    if (
      status >= 200 &&
      status < 300 &&
      data &&
      (!data.errors || (data.errors && Object.keys(data.errors).length === 0))
    ) {
      return { response: data }
    } else {
      yield put(apiErrorAction({ ...response, failed: true, loading: false }))

      yield put(
        setErrorAction({
          type,
          payload:
            typeof data === 'object' && data !== null
              ? { ...data, failed: true, loading: false }
              : { error: response.statusText, failed: true, loading: false }
        })
      )

      return {
        errors: /*typeof data === 'object' && data !== null ? data :*/ response
      }
    }
  } catch (error) {
    yield put(apiErrorAction({ ...error, failed: true }))
  } finally {
    yield put(
      setLoadingAction({
        type,
        payload: { loading: false }
      })
    )
  }
}
